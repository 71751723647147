import Snackbar from '@/components/Snackbar';
import type { Color } from '@/utils/material';
import type { SyntheticEvent } from 'react';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';

const showToast = (severity: Color, msg: string, options?: ToastOptions) => {
  toast(
    ({ closeToast }) => (
      <Snackbar
        onClose={closeToast as (event: SyntheticEvent<Element, Event>) => void}
        severity={severity}
        msg={msg}
      />
    ),
    options,
  );
};

const message = {
  error: (msg: string, options?: ToastOptions) => showToast('error', msg, options),
  success: (msg: string, options?: ToastOptions) => showToast('success', msg, options),
  info: (msg: string, options?: ToastOptions) => showToast('info', msg, options),
  warning: (msg: string, options?: ToastOptions) => showToast('warning', msg, options),
};

export default message;
