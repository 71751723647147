import type { Address } from '@/domain/address';
import type { EmailAddress } from '@/domain/emails';
import type { Organization } from '@/domain/organization';
import type { Phone } from '@/domain/phone';
import type { Vendor } from '@/domain/vendor';
import { crudService } from '@/services/apiClient';
import { setAccessToken, setRefreshToken } from '@/utils/auth';
import { api } from '@/utils/configuration';

export interface OrganizationRequest {
  name: string;
  address: Address;
  phones: Partial<Phone>[];
  emailAddresses: Partial<EmailAddress>[];
}

interface CreateLoginRequest {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
}

interface SignUpRequest {
  organization: OrganizationRequest;
  login: CreateLoginRequest;
  token: string;
}

interface SignUpResponse {
  organization: Organization;
  vendor: Vendor;
}

interface LoginRequest {
  username: string;
  password: string;
}

interface RefreshTokenRequest {
  refreshToken: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export const AuthService = {
  signUp: (signUpRequest: SignUpRequest) => {
    return crudService.post<SignUpResponse>(signUpRequest, `${api.AUTH_URL}/register-org`);
  },
  login: (loginRequest: LoginRequest) => {
    return crudService
      .post<LoginResponse>(loginRequest, `${api.AUTH_URL}/login`)
      .then((response) => {
        setAccessToken(response.accessToken);
        setRefreshToken(response.refreshToken);
        return response;
      });
  },
  refreshToken: (refreshTokenRequest: RefreshTokenRequest) => {
    return crudService
      .post<LoginResponse>(refreshTokenRequest, `${api.AUTH_URL}/refresh`)
      .then((response) => {
        setAccessToken(response.accessToken);
        setRefreshToken(response.refreshToken);
        return response;
      });
  },
};
