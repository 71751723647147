import type { LookupParams } from '@/domain/base';
import type { PartyLookupParams, PartyLookupResult } from '@/domain/person';
import type { UserLookup } from '@/domain/user';
import { getAxiosFetcher } from '@/services/apiClient';
import { api } from '@/utils/configuration';

const LookupService = {
  user: getAxiosFetcher<UserLookup[], [LookupParams?]>((params = {}) => [
    `${api.USER_URL}/lookup`,
    { params },
  ]),

  party: getAxiosFetcher<PartyLookupResult[], [PartyLookupParams?]>((params) => [
    `${api.PARTIES_URL}/lookup`,
    { params },
  ]),
};

export default LookupService;
